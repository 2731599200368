import {
  Brands,
  DBData,
  Earnings,
  EmploymentStatus,
  FirstEvents,
  LendersData,
  ManualAddr,
  PDFTYPE,
  REFUNDS,
  SectionIds,
  STEP,
  UserData,
} from '../constants'
import { useRouter } from 'next/router'
import React, {
  createContext,
  Dispatch,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getElementTop as getTop, scrollToTop, validateEmail, validateRefund } from '../utils'
import useViewportSize from '../hooks/useViewportSize'

const useValue = () => {
  const router = useRouter()

  const [checkedYears, setCheckedYears] = useState<string[]>([])
  const [claimValue, setClaimValue] = useState<number>(0)
  const [showPulse, setShowPulse] = useState<boolean>(false)
  const [addressList, setAddressList] = useState([] as object[])
  const [linkCode, setLinkCode] = useState<any>(null)
  const [dbData, setDbData] = useState({} as DBData)
  const [userEmail, setUserEmail] = useState<any>(null)
  const [userPhone, setUserPhone] = useState<any>(null)
  const [userIp, setUserIp] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [fileURL, setFileURL] = useState<PDFTYPE | null>(null)
  const [ready, setReady] = useState(false)
  const [showLoadingPage, setShowLoadingPage] = useState(false)
  const [signatureTermsChecked, setSignatureTermsChecked] = useState(true)
  const titleRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)
  const [step, setStep] = useState<STEP>(STEP.INIT)
  const [utmParams, setUtmParams] = useState({} as Record<string, string>)
  const [device, setDevice] = useState('')
  const [selectedAddress, setSelectedAddress] = useState('')
  const [useAutoComplete, setUseAutoComplete] = useState(false)
  const [showBanner, setShowBanner] = React.useState(true)
  const [currentStepHeight, setCurrentStepHeight] = useState(700)
  const [emailValidation, setEmailValidation] = useState<Record<'loading' | 'isValid' | 'proceed', boolean>>({
    loading: false,
    isValid: false,
    proceed: false,
  })
  const [summaryConfirmed, setSummaryConfirmed] = useState(false)
  const [stepLoading, setStepLoading] = useState(false)
  const [refunds, setRefunds] = useState({} as REFUNDS)
  const [brand, setBrand] = useState<Brands | null>(Brands.ACG)
  const [lendersData, setLendersData] = useState<LendersData>({
    selectedLenders: [],
    firstEvent: true,
  })
  const [tableMenuPosition, setTableMenuPosition] = useState({
    x: 0,
    y: 0,
    show: false,
    id: '',
  })
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    postCode: '',
    address: '',
    phone: '',
    day: '',
    month: '',
    year: '',
    signatureData: '',
    earnings: '' as Earnings,
    insurance: '',
    amount: '',
    employment_status: '' as EmploymentStatus,
  } as UserData)
  const [firstEvents, setFirstEvents] = useState({
    firstName: true,
    lastName: true,
    email: true,
    postCode: true,
    address: true,
    phone: true,
    day: true,
    month: true,
    year: true,
    earnings: true,
    signatureData: true,
    insurance: true,
    amount: true,
    signatureTermsChecked: true,
    addressLine1: true,
    townOrCity: true,
    employment_status: true,
    lendersData: true,
    otherLender: true,
    summary: true,
    details: true,
    refunds: true,
  })
  const [manualAddr, setManualAddr] = useState({
    addressLine1: '',
    addressLine2: '',
    townOrCity: '',
    postCode: '',
  } as ManualAddr)
  const [detailsChecked, setDetailsChecked] = useState(false)
  const [stepValid, setStepValid] = useState({} as Record<STEP, boolean>)
  const [useManualAddr, setUseManualAddr] = useState(false)
  const [taxYears, setTaxYears] = useState<any>({
    firstEvents: {
      APR062018_APR052019: true,
      APR062019_APR052020: true,
      APR062020_APR052021: true,
      APR062021_APR052022: true,
    },
    tax_years: {},
  })
  const [editingSummary, setEditingSummary] = useState(false)
  const [payoutDone, setPayoutDone] = useState<Record<string, boolean>>(
    lendersData.selectedLenders.reduce(
      (obj, key) => ({
        ...obj,
        [key]: false,
      }),
      {}
    )
  )
  const [showBackdrop, setShowBackdrop] = useState(true)
  const [submittingClaims, setSubmittingClaims] = useState(false)

  useEffect(() => {
    if (router.query) {
      let utmParams: any = {}
      Object.keys(router.query).forEach(key => {
        if (key.startsWith('utm_')) {
          utmParams[key] = router.query[key]
        }
        if (key === 's') {
          utmParams['utm_source'] = router.query[key]
        }
        if (key === 'open-pdf') {
          openPdf(router.query[key] as unknown as PDFTYPE)
        }
        setUtmParams(utmParams)
      })
    }
  }, [router.query, router])

  const isMobile = useViewportSize().width < 480

  const scrollToIncompleteSection = () => {
    const getElementTop = (id: string) => {
      return getTop(id) - (isMobile ? 120 : 0)
    }
    switch (step) {
      case STEP.EARNINGS:
        if (!userData.employment_status) {
          scrollToTop()
        } else if (!userData.earnings) {
          scrollToTop(getElementTop(SectionIds.EARNINGS))
        }
        break
      case STEP.REFUNDS:
        parentLoop: for (const lender of [...lendersData.selectedLenders]) {
          for (let j = 0; j < refunds[lender].length; j++) {
            if (!validateRefund(refunds[lender][j])) {
              scrollToTop(getElementTop(lender + '-' + j + '-' + SectionIds.REFUNDS))
              break parentLoop
            }
            if (j === refunds[lender].length - 1 && !payoutDone[lender]) {
              scrollToTop(getElementTop(lender + '-' + j + '-' + SectionIds.REFUNDS))
              break parentLoop
            }
          }
        }
        break
      case STEP.CONTACT:
        const { firstName, lastName, email, phone, address, day, month, year } = userData
        if (!firstName || !lastName) {
          if (brand === Brands.QTC) scrollToTop(getElementTop(SectionIds.NAME))
        } else if (!day || !year || !month) {
          scrollToTop(getElementTop(SectionIds.DOB))
        } else if (!phone) {
          scrollToTop(getElementTop(SectionIds.PHONE))
        } else if (!email) {
          scrollToTop(getElementTop(SectionIds.EMAIL))
        } else if (!address) {
          scrollToTop(getElementTop(SectionIds.ADDRESS))
        } else if (!detailsChecked) {
          scrollToTop(getElementTop(SectionIds.CONTACT_CONFIRM))
        }
        break
      case STEP.SUMMARY:
        if (!summaryConfirmed) {
          scrollToTop(getElementTop(SectionIds.SUMMARY_CONFIRM))
        }
        break
      default:
        scrollToTop()
    }
  }
  const catchError = (_e: any) => {
    // Bugsnag.notify(new Error(e.message), function (event) {
    //   event.severity = "error";
    //   event.context = "Step Error";
    //   event.addMetadata("step", { step: Object.keys(STEP)[step] });
    // });
  }
  const openPdf = (path: PDFTYPE) => {
    setFileURL(path)
  }
  const handleFormChange = (key: string, value: string) => {
    setUserData(prev => {
      const newData = {
        ...prev,
        [key]: value,
      }
      localStorage.setItem('usr_data', JSON.stringify(newData))
      return newData
    })

    setFirstEvents(prev => ({
      ...prev,
      [key]: false,
    }))
    localStorage.setItem('step_valid', JSON.stringify(stepValid))
  }

  const checkStepCompleteness = (step: STEP) => {
    switch (step) {
      case STEP.INIT:
        return brand === Brands.QTC ? !!localStorage.getItem('usr_data') : stepValid[STEP.NAME] && stepValid[STEP.DOB]
      case STEP.EARNINGS:
        return stepValid[STEP.EARNINGS]
      case STEP.LENDERS:
        return stepValid[STEP.LENDERS]
      case STEP.REFUNDS:
        return stepValid[STEP.REFUNDS]
      case STEP.CONTACT:
        return stepValid[STEP.PHONE] && emailValidation.isValid && stepValid[STEP.ADDRESS] && detailsChecked
      case STEP.ADDRESS:
        return stepValid[STEP.ADDRESS]
      case STEP.NAME:
        return userData && userData.firstName?.length > 1 && userData.lastName?.length > 1
      case STEP.EMAIL:
        return validateEmail(userData.email) && emailValidation.isValid
      case STEP.PHONE:
        return userData.phone?.length === 11 && userData.phone?.startsWith('07')
      case STEP.DOB:
        return !!(userData.day && userData.month && userData.year)
      case STEP.INSURANCE:
        return stepValid[STEP.INSURANCE]
      default:
        return false
    }
  }
  const getNextStep = () => {
    let i = step
    while (i < Object.keys(STEP).length * 0.5) {
      if (
        (brand === Brands.QTC && i === STEP.CONTACT) ||
        (brand === Brands.ACG && i >= STEP.NAME && i <= STEP.ADDRESS)
      ) {
        i++
        continue
      }
      if (!checkStepCompleteness(i)) {
        break
      }
      i++
    }
    return i
  }

  return {
    scrollToIncompleteSection,
    checkedYears,
    setCheckedYears,
    taxYears,
    setTaxYears,
    showPulse,
    setShowPulse,
    addressList,
    setAddressList,
    claimValue,
    setClaimValue,
    linkCode,
    setLinkCode,
    dbData,
    setDbData,
    userEmail,
    setUserEmail,
    userIp,
    setUserIp,
    userPhone,
    setUserPhone,
    fileURL,
    setFileURL,
    open,
    setOpen,
    openPdf,
    ready,
    setReady,
    userData,
    setUserData,
    firstEvents,
    setFirstEvents,
    showLoadingPage,
    setShowLoadingPage,
    handleFormChange,
    signatureTermsChecked,
    setSignatureTermsChecked,
    titleRef,
    step,
    setStep,
    catchError,
    utmParams,
    setUtmParams,
    device,
    setDevice,
    manualAddr,
    setManualAddr,
    useManualAddr,
    setUseManualAddr,
    selectedAddress,
    setSelectedAddress,
    getNextStep,
    useAutoComplete,
    setUseAutoComplete,
    showBanner,
    setShowBanner,
    currentStepHeight,
    setCurrentStepHeight,
    nextButtonRef,
    emailValidation,
    setEmailValidation,
    stepLoading,
    setStepLoading,
    refunds,
    setRefunds,
    lendersData,
    setLendersData,
    summaryConfirmed,
    setSummaryConfirmed,
    brand,
    setBrand,
    detailsChecked,
    setDetailsChecked,
    stepValid,
    setStepValid,
    editingSummary,
    setEditingSummary,
    tableMenuPosition,
    setTableMenuPosition,
    payoutDone,
    setPayoutDone,
    submittingClaims,
    setSubmittingClaims,
    showBackdrop,
    setShowBackdrop,
  }
}

export interface SharedValue {
  openPdf: (path: PDFTYPE) => void
  showPulse: boolean
  setShowPulse: Dispatch<SetStateAction<boolean>>
  claimValue: number
  setClaimValue: Dispatch<SetStateAction<number>>
  taxYears: {
    firstEvents: {
      APR062018_APR052019: boolean
      APR062019_APR052020: boolean
      APR062020_APR052021: boolean
      APR062021_APR052022: boolean
    }
    tax_years: Record<string, string>
  }
  setTaxYears: Dispatch<SetStateAction<{ [x: string]: any }>>
  addressList: Record<string, any>[]
  setAddressList: Dispatch<SetStateAction<Record<string, any>[]>>
  linkCode: string
  setLinkCode: Dispatch<SetStateAction<string>>
  dbData: DBData
  setDbData: Dispatch<SetStateAction<DBData>>
  userEmail: string
  setUserEmail: Dispatch<SetStateAction<string>>
  userPhone: string
  setUserPhone: Dispatch<SetStateAction<string>>
  userIp: string
  setUserIp: Dispatch<SetStateAction<string>>
  fileURL: PDFTYPE | null
  setFileURL: Dispatch<SetStateAction<PDFTYPE | null>>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  ready: boolean
  setReady: Dispatch<SetStateAction<boolean>>
  userData: UserData
  setUserData: Dispatch<SetStateAction<UserData>>
  manualAddr: ManualAddr
  setManualAddr: Dispatch<SetStateAction<ManualAddr>>
  firstEvents: FirstEvents
  setFirstEvents: Dispatch<SetStateAction<FirstEvents>>
  showLoadingPage: boolean
  setShowLoadingPage: Dispatch<SetStateAction<boolean>>
  signatureTermsChecked: boolean
  setSignatureTermsChecked: Dispatch<SetStateAction<boolean>>
  handleFormChange: (key: string, value: string) => void
  titleRef: RefObject<HTMLDivElement>
  nextButtonRef: RefObject<HTMLButtonElement>
  step: STEP
  setStep: Dispatch<SetStateAction<STEP>>
  catchError: (e: any) => void
  utmParams: Record<string, string>
  setUtmParams: Dispatch<SetStateAction<Record<string, string>>>
  device: string
  setDevice: Dispatch<SetStateAction<string>>
  selectedAddress: string
  setSelectedAddress: Dispatch<SetStateAction<string>>
  useManualAddr: boolean
  setUseManualAddr: Dispatch<SetStateAction<boolean>>
  useAutoComplete: boolean
  setUseAutoComplete: Dispatch<SetStateAction<boolean>>
  getNextStep: () => STEP
  scrollToIncompleteSection: () => void
  showBanner: boolean
  setShowBanner: Dispatch<SetStateAction<boolean>>
  stepLoading: boolean
  setStepLoading: Dispatch<SetStateAction<boolean>>
  currentStepHeight: number
  setCurrentStepHeight: Dispatch<SetStateAction<number>>
  emailValidation: Record<'loading' | 'isValid' | 'proceed', boolean>
  setEmailValidation: Dispatch<SetStateAction<Record<string, boolean>>>
  refunds: REFUNDS
  setRefunds: Dispatch<SetStateAction<REFUNDS>>
  lendersData: LendersData
  setLendersData: Dispatch<SetStateAction<LendersData>>
  summaryConfirmed: boolean
  setSummaryConfirmed: Dispatch<SetStateAction<boolean>>
  setBrand: Dispatch<SetStateAction<Brands | null>>
  brand: Brands | null
  setDetailsChecked: Dispatch<SetStateAction<boolean>>
  detailsChecked: boolean
  stepValid: Record<STEP, boolean>
  setStepValid: Dispatch<SetStateAction<Record<STEP, boolean>>>
  editingSummary: boolean
  setEditingSummary: Dispatch<SetStateAction<boolean>>
  tableMenuPosition: { x: number; y: number; show: boolean; id: string }
  setTableMenuPosition: Dispatch<SetStateAction<{ x: number; y: number; show: boolean; id: string }>>
  payoutDone: Record<string, boolean>
  setPayoutDone: Dispatch<SetStateAction<Record<string, boolean>>>
  showBackdrop: boolean
  setShowBackdrop: Dispatch<SetStateAction<boolean>>
  submittingClaims: boolean
  setSubmittingClaims: Dispatch<SetStateAction<boolean>>
}

export const SharedValueContext = createContext({} as SharedValue)

export const SharedValueProvider = ({ children }: React.PropsWithChildren) => {
  const value = useValue()

  return <SharedValueContext.Provider value={value}>{children}</SharedValueContext.Provider>
}

export const useSystemValues = () => useContext(SharedValueContext)
