import { forwardRef } from 'react'
import { Button as Btn, ButtonProps } from '../shadcn/button'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, ...props }, ref) => {
  return (
    <Btn
      ref={ref}
      size="sm"
      type="button"
      className={
        'gap-1 focus:outline-none focus:ring-4 focus:ring-gray-300 active:outline-none active:ring-4 active:ring-gray-300 dark:border-gray-500 dark:focus:ring-gray-500 dark:active:ring-gray-500 ' +
        className
      }
      {...props}
    >
      {children}
    </Btn>
  )
})

export default Button
