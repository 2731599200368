import { Inter } from 'next/font/google'

export type UserData = {
  firstName: string
  lastName: string
  email: string
  postCode: string
  address: string
  phone: string
  day: string
  month: string
  year: string
  earnings: Earnings
  employment_status: EmploymentStatus
  signatureData: string
  insurance: string
}

export type ManualAddr = {
  addressLine1: string
  addressLine2?: string
  townOrCity: string
  postCode: string
}
export type FirstEvents = {
  [key in keyof UserData]: boolean
} & { [key in keyof ManualAddr]: boolean } & {
  lendersData: boolean
  otherLender: boolean
  amount: boolean
  refunds: boolean
  signatureTermsChecked: boolean
  summary: boolean
  details: boolean
}

export type REFUNDS = Record<
  string,
  {
    year: string
    amount: string
    tax_deduction: string
    id: string
    firstEvent: {
      [key: string]: boolean
    }
  }[]
>
export type LendersData = {
  selectedLenders: string[]
  firstEvent: boolean
}

export const font = Inter({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-inter',
})

export enum TAX_TYPE {
  NONE,
  LAST_YEAR,
  CURRENT_YEAR,
  BOTH,
}

export enum Earnings {
  LessThan12570 = 'Less than £12,570',
  Between12571And50270 = '£12,571 to £50,270',
  MoreThan50271 = 'More than £50,271',
}

export enum EmploymentStatus {
  EMPLOYED = 'Employed',
  SELF_EMPLOYED = 'Self employed',
  UNEMPLOYED = 'Unemployed',
  RETIRED = 'Retired',
}

export enum STEP {
  STORYBOOK_SHELL = -2,
  INIT = -1,
  EARNINGS,
  LENDERS,
  REFUNDS,
  CONTACT,
  NAME,
  DOB,
  EMAIL,
  PHONE,
  ADDRESS,
  INSURANCE,
  SUMMARY,
  SIGNATURE,
  ALL_DONE,
}

export enum ToastError {
  SIGNATURE = 'signature-toast',
  INSURANCE = 'insurance-toast',
  TELEPHONE = 'telephone-toast',
  EMAIL = 'email-toast',
  POSTCODE = 'postcode-toast',
  SELECTED_ADDRESS = 'selected-address-toast',
  ADDRESS_LINE_1 = 'address-line-1-toast',
  TOWN = 'town-toast',
  DETAILS_CONFIRMATION = 'details-confirmation-toast',
  SUMMARY_TERMS = 'summary-terms-toast',
  LENDER = 'lender-toast',
  FIRST_NAME = 'first-name-toast',
  LAST_NAME = 'last-name-toast',
  DOB = 'dob-toast',
  CONTACT_DETAILS = 'contact-details-toast',
  EmploymentStatus = 'employment-status-toast',
  Income = 'income-toast',
  ADDRESS_PULSE = 'address-pulse-toast',
  CUSTOM = 'custom-toast',
}

export enum SectionIds {
  EMPLOYMENT_STATUS = 'employment-status-section',
  EARNINGS = 'earnings-section',
  NAME = 'name-section',
  DOB = 'dob-section',
  PHONE = 'phone-section',
  EMAIL = 'email-section',
  REFUNDS = 'refunds-section',
  ADDRESS = 'address-section',
  CONTACT_CONFIRM = 'contact-confirm-section',
  SUMMARY_CONFIRM = 'summary-confirm-section',
}

export type DBData = {
  id?: string
  createdAt: Date
  firstName: string
  lastName: string
  email: string
  postCode: string
  address: string
  insurance: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  utm_term: string
  email_link: string
  signatureUrl: string
  birthdate_str: string
  earnings: Earnings
  tax_years: Record<string, string>
  phone: string
  imported: boolean
  user_ip: string
  link: string
  link_code: string
  brand: Brands
  domain: string
  employment_status: EmploymentStatus
  refunds_summary: {
    [x: string]: {
      year: string
      amount: number
    }
  }
  refunds_breakdown: REFUNDS
}

export const DATA8_TOKEN =
  'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEYXRhOCIsInN1YiI6ImVtbWFudWVsaGVtYXJ4eWxsQGdtYWlsLmNvbSIsImV4cCI6MTczMzA1NDQwMCwiY24iOiJDbGFpbWluZyBNYWRlIEVhc3kiLCJhdWQiOlsiRW1haWxWYWxpZGF0aW9uIiwiSW50ZXJuYXRpb25hbFRlbGVwaG9uZVZhbGlkYXRpb24iXX0.dvM7qv6Htmy0dr4eeBvNUcvO30e11WxNnqgugTXHTGc'

export enum Brands {
  ACG,
  QTC,
}
export enum PDFTYPE {
  TERMS_OF_ENGAGEMENT = 'terms-of-engagement',
  PRIVACY_POLICY = 'privacy-policy',
  SIGNATURE_TERMS = 'signature-terms',
  R40 = 'r40',
  AUTHORIZE_AGENT_648 = 'authorize-agent',
}
