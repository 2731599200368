import { useEffect, useState } from 'react'

const useViewportSize = () => {
  const [viewportWidth, setViewportWidth] = useState(0)
  const [viewportHeight, setViewportHeight] = useState(0)

  useEffect(() => {
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const handleResize = () => {
      setViewportWidth(vw)
      setViewportHeight(vh)
    }

    // Initial viewport width
    setViewportWidth(vw)
    setViewportHeight(vh)

    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { width: viewportWidth, height: viewportHeight }
}

export default useViewportSize
