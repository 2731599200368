import '@qtc-repo/ui/styles/globals.css'

import type { AppProps } from 'next/app'
import { ValueProvider } from '@/contexts/ValueContext'
import { ThemeProvider } from '@qtc-repo/common/contexts/ThemeContext'
import { useEffect } from 'react'
import getConfig from 'next/config'

import PasswordProtectionWrapper from '@/components/PasswordProtectionWrapper'

export default function App({ Component, pageProps }: AppProps) {
  const { publicRuntimeConfig } = getConfig()

  const { domain } = publicRuntimeConfig.site
  useEffect(() => {
    try {
      if (process.env.NODE_ENV === 'production') {
        document.domain = domain
      }
    } catch {}
  }, [domain])
  return (
    <ValueProvider>
      <ThemeProvider>
        <PasswordProtectionWrapper>
          <Component {...pageProps} />
        </PasswordProtectionWrapper>
      </ThemeProvider>
    </ValueProvider>
  )
}
