import { SharedValue, SharedValueProvider, useSystemValues as useValues } from '@qtc-repo/common/contexts/SharedContext'
import { createContext, useContext } from 'react'

interface Value {}

const ValueContext = createContext({} as Value & Partial<SharedValue>)

export const ValueProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <ValueContext.Provider value={{}}>
      <SharedValueProvider>{children}</SharedValueProvider>
    </ValueContext.Provider>
  )
}

export const useSystemValues = () => ({
  ...useValues(),
  ...useContext(ValueContext),
})
