import { REFUNDS, ToastError } from './constants'
import { toast as SonnerToast } from 'sonner'
import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const validateEmail = (e: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i.test(e)
}

export const validatePAYE = (e: string) => {
  return /(\w|[0-9])+\/+\w|[0-9]+i/.test(e)
}

export const hasObjectValueChanged = (newObj: Record<string, any>, baseObj: Record<string, any>) => {
  return Object.keys(newObj).some(key => newObj[key] !== baseObj[key])
}

export const isObjectFilled = (obj: Record<string, any>, fields?: string[]) => {
  return (
    !!Object.keys(obj).length &&
    [...(Array.isArray(fields) && fields.length ? fields : Object.keys(obj))].every(
      key => !['', null, undefined].includes(obj[key])
    )
  )
}

export const getObjectDifference = (base: Record<string, any>, comp: Record<string, any>): Record<string, any> => {
  const result = {} as Record<string, any>
  for (const key in comp) {
    if (comp[key] && (!(key in base) || base[key] !== comp[key])) {
      result[key] = comp[key]
    }
  }
  return result
}

export const excludeEmptyFields = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce(
    (result, item) => {
      if (obj[item] !== null && obj[item] !== '') {
        result[item] = obj[item]
      }
      return result
    },
    {} as Record<string, any>
  )
}

export const formatUserDetails = (details: Record<string, any>): Record<string, any> & { birthdate_str: string } => {
  const { day, month, year, signatureData, amount, ...others } = details
  return {
    ...others,
    birthdate_str: `${day}/${month}/${year}`,
  }
}

/**
 * Check for cookies enabled before doing localstorage
 * @returns boolean
 */
export function isCookieSupported() {
  try {
    let cookieEnabled = navigator.cookieEnabled

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie'
      // Create cookie
      document.cookie = 'cookietest=1'
      cookieEnabled = document.cookie.includes('cookietest=')
      // Delete cookie
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
    }
    return cookieEnabled
  } catch (e) {
    return false
  }
}

export const getBase64Image = async (url: string): Promise<string> => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.src = url
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = () => {
      resolve('')
    }
  })
}

export const disableScroll = () => {
  // Get the current page scroll position
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  // if any scroll is attempted, set this to the previous value
  document.body.style.overflow = 'hidden'
  window.onscroll = () => {
    window.scrollTo(scrollLeft, scrollTop)
  }
}

export const enableScroll = () => {
  window.onscroll = () => {}
  document.body.style.overflow = 'auto'
}

export const scrollToTop = (top?: number) => {
  setTimeout(() => window.scrollTo({ top: top ?? 0, behavior: 'smooth' }), 100)
}

export const getElementTop = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    const { top } = element.getBoundingClientRect()
    return top + window.scrollY - 20 // allow 20px space at top of page
  }
  return 0
}

export const validateRefund = (refund: REFUNDS[string][number]) => {
  return (
    refund?.amount &&
    Number(refund.amount.replace(/,/g, '')) > 0 &&
    Number(refund.tax_deduction.replace(/,/g, '')) > 0 &&
    refund?.year
  )
}
export const toast = (id: ToastError, customMessage?: string, customId?: string) => {
  let message: string
  switch (id) {
    case ToastError.SUMMARY_TERMS:
      message = 'Please confirm you accept the Terms of Engagement'
      break
    case ToastError.LENDER:
      message = 'Please select at least one lender'
      break
    case ToastError.DETAILS_CONFIRMATION:
      message = 'Please confirm that you have read and understood the Privacy Policy'
      break
    case ToastError.INSURANCE:
      message = 'Please provide a valid National Insurance (NI) number'
      break
    case ToastError.SIGNATURE:
      message = 'Please sign in the box provided'
      break
    case ToastError.EmploymentStatus:
      message = 'Please select your employment status'
      break
    case ToastError.Income:
      message = 'Please select your annual income'
      break
    case ToastError.FIRST_NAME:
      message = 'Please provide a valid first name'
      break
    case ToastError.LAST_NAME:
      message = 'Please provide a valid last name'
      break
    case ToastError.DOB:
      message = 'Please provide a valid date of birth'
      break
    case ToastError.EMAIL:
      message = 'Please enter a valid email address'
      break
    case ToastError.TELEPHONE:
      message = 'Please enter a valid mobile number'
      break
    case ToastError.POSTCODE:
      message = 'Please enter a valid postcode'
      break
    case ToastError.ADDRESS_LINE_1:
      message = 'Please enter a valid address line 1'
      break
    case ToastError.TOWN:
      message = 'Please enter a valid town or city'
      break
    case ToastError.SELECTED_ADDRESS:
      message = 'Please select your address from the list'
      break
    case ToastError.ADDRESS_PULSE:
      message = 'Please click the search button to find your address'
      break
    case ToastError.CUSTOM:
      message = customMessage!
      id = customId! as ToastError
      break
    default:
      message = 'Please ensure all fields are filled correctly'
  }
  SonnerToast.error(message, { duration: Infinity, id, dismissible: true })
}

export const padValue = (value: number, max: number, position?: 'start' | 'end', padWith = '0') => {
  const padding = padWith.repeat(Math.max((max + '').length - (value + '').length, 0))
  return position === 'start' ? padding + value : value + padding
}

export const isWithinRange = (value: number, min: number, max: number) => {
  return value >= min && value <= max
}
